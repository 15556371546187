<template>
  <v-row>
    <v-col cols="12">
      Example Modal
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HomeSalesFigureModal"
};
</script>

<style scoped></style>
